import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Twitter from "../Twitter"
import { useStaticQuery, graphql } from "gatsby"
import { AiFillPlayCircle } from "react-icons/ai"
import styled from "styled-components"
import Calendar from "./Calendar"

const StyledIcon = styled(AiFillPlayCircle)`
  position: absolute;

  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: white;
`

const StyledImage = styled.img`
  filter: brightness(50%);
`

const StyledLink = styled.a`
  display: block;
  transition: transform 0.3s;
  position: relative;
`

const PastorDescription = () => {
  const { allYoutubeVideo } = useStaticQuery(graphql`
    {
      allYoutubeVideo(filter: { title: { regex: "/Herbata/" } }, limit: 4) {
        nodes {
          title
          videoId
          thumbnail {
            url
          }
        }
      }
    }
  `)

  return (
    <>
      <section className="bg-neutral-200 py-8">
        <div className="max-w-screen-xl mx-auto">
          <div className="px-4 py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 ">
              <div
                className="relative flex items-center bg-white"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <span className="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-white lg:block lg:-right-16"></span>

                <div className="p-8 sm:p-16 lg:p-24">
                  <h2 className="text-2xl font-bold sm:text-3xl">
                    Pastor Zboru KChB OAZA w Wiśle - Damian Zajonc
                  </h2>
                  <div className="md:float-left mt-8 mr-5 lg:hidden">
                    <StaticImage
                      data-sal="slide-right"
                      data-sal-easing="ease"
                      data-sal-duration="500"
                      src="../../assets/photo/pastor4.jpg"
                      alt="pastor"
                    />
                  </div>

                  <p className="mt-6">
                    „Razem z rodziną (żoną Joanną, dziećmi: Jakubem i
                    Stanisławem) zdecydowaliśmy się poświęcić nasze życie na
                    służbę w Kościele Pana Jezusa, odpowiadając na Boże
                    powołanie do służby pastorskiej w zborze Kościoła
                    Chrześcijan Baptystów „Oaza” w Wiśle. To tu wzrastamy we
                    wspólnej wierze z ludźmi, którzy obdarowali nas miłością i
                    których również my pokochaliśmy całym sercem. Stawiamy się
                    do dyspozycji, aby Pan Jezus Chrystus został uwielbiony, a
                    służba „Oazy” była błogosławieństwem dla wielu”.
                  </p>
                  <br />
                  <p>
                    W 2020 roku Bóg posłał br. Damiana Zajonca do naszej
                    społeczności, aby zapełnić trwającą niespełna 2 lata lukę w
                    posłudze pastorskiej zboru. Od tamtego czasu wiernie służył
                    społeczności, prowadząc pracę duszpasterską oraz
                    kaznodziejską, w charakterze pełniącego obowiązki pastora.
                    Następnie ordynowany na pełnoprawnego pastora zboru we
                    Wrześniu 2022, rozpoczął służbę z błogosławieństwem zboru
                    oraz przedstawicieli Kościoła Chrześcijan Baptystów w RP.
                  </p>
                </div>
              </div>
              <div
                className="relative z-10 lg:py-16"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className="relative h-64 sm:h-80 lg:h-full hidden lg:block ">
                  <StaticImage
                    className="absolute inset-0 object-cover w-full h-full"
                    data-sal="slide-right"
                    data-sal-easing="ease"
                    data-sal-duration="500"
                    src="../../assets/photo/pastor4.jpg"
                    alt="pastor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-3 items-center justify-center gap-4">
            <div className="order-last lg:order-first row-span-2">
              <Twitter />
            </div>
            <div className="col-span-2 grid md:grid-cols-2 gap-4">
              {allYoutubeVideo.nodes.map(({ videoId, thumbnail, title }) => {
                const [titleVideo] = title.split(" - ")
                return (
                  <div>
                    <StyledLink
                      href={`https://www.youtube.com/watch?v=${videoId}`}
                      target="_blank"
                    >
                      <StyledImage src={thumbnail.url} />
                      <StyledIcon />
                    </StyledLink>
                    <p className="mt-1 font-bold">{titleVideo}</p>
                  </div>
                )
              })}
            </div>
            <div className="my-8 bg-white p-2 lg:p-8 rounded-lg col-span-2 lg:col-start-2">
              <Calendar />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PastorDescription
