import React from "react"

import PageTemplate from "../templates/PageTemplate"
import PastorDescription from "../components/Pastor/PastorDescription"
import Helmet from "react-helmet"

const PastorPage = () => {
  return (
    <PageTemplate>
      <Helmet title="Moja oaza - Pastor" />
      <PastorDescription />
    </PageTemplate>
  )
}

export default PastorPage
