import React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import plLocale from "@fullcalendar/core/locales/pl"
import Tippy from "@tippyjs/react"

function renderEventContent(eventInfo) {
  return (
    <Tippy
      content={
        <div className="bg-white p-1 border-slate-400 border-2 rounded-md border">
          <i>{eventInfo.event.title}</i>
        </div>
      }
    >
      <div className="w-full flex items-center">
        <div className="fc-daygrid-event-dot"></div>
        <div className="fc-event-time">{eventInfo.timeText}</div>
        <div className="fc-event-title">{eventInfo.event.title}</div>
      </div>
    </Tippy>
  )
}

const Calendar = () => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      locale={plLocale}
      events={[]}
      eventContent={renderEventContent}
      headerToolbar={{
        start: "title",
        end: "prev,next",
      }}
    />
  )
}

export default Calendar
