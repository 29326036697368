import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

const StyledSkeleton = styled.div`
  background-color: #fff;
  width: 100%;
  height: ${() => (isMobile ? "600px" : "1300px")};
  border: 1px solid #ddd;
  border-radius: 13px;
  overflow: hidden;
`

const Twitter = () => {
  return (
    <a
      class="twitter-timeline"
      height={isMobile ? 600 : 1300}
      href="https://twitter.com/DamianZajonc?ref_src=twsrc%5Etfw"
    >
      <StyledSkeleton className="animate-pulse">
        <div className="p-4">
          <div class="h-2 bg-gray-400 rounded p-4"></div>
        </div>
        <hr />
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
        <div className="p-2">
          <div className="p-4 flex items-center gap-2">
            <div className="rounded-full bg-gray-400 h-10 w-10"></div>
            <div className="grow">
              <div className="h-2 bg-gray-400 rounded p-2"></div>
              <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            </div>
          </div>
          <div className="px-4">
            <div className="h-2 bg-gray-400 rounded p-2"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
            <div className="h-2 bg-gray-400 rounded p-2 mt-1"></div>
          </div>
        </div>
      </StyledSkeleton>
    </a>
  )
}

export default Twitter
